import { createRouter, createWebHistory } from "vue-router";
import HomeView from "@/views/HomeView.vue";
import LoginView from "@/views/LoginView.vue";

import { supabase } from "@/ts/supabase";

const router = createRouter({
	history: createWebHistory(import.meta.env.BASE_URL),
	routes: [
		{
			path: "/",
			name: "home",
			component: HomeView
		},
		{
			path: "/login",
			name: "login",
			component: LoginView
		}
	]
});

router.beforeEach(async (to, from, next) => {
	const { error } = await supabase.auth.getUser();

	if (error && to.name !== "login") {
		next("login");
		return;
	} else if (!error && to.name == "login") {
		next(from);
		return;
	}

	next();
});

export default router;
