<template>
	<section class="home-view">
		<PrimeToolbar class="toolbar-top" style="height: 64px">
			<template #start>
				<img class="toolbar-logo" src="@/assets/goodhue-logo.svg" />
			</template>
			<template #end>
				<div class="flex flex-row align-center" style="gap: 8px">
					<span style="color: var(--p-surface-400)">{{ userEmail }}</span>
					<PrimeButton label="Logout" severity="secondary" @click="logout"></PrimeButton>
				</div>
			</template>
		</PrimeToolbar>

		<PrimePaginator
			v-if="images.length > 0 && totalImagesCount > IMAGES_PER_PAGE"
			:rows="IMAGES_PER_PAGE"
			:totalRecords="totalImagesCount"
			@page="onPageChanged"
			:first="(currentPage - 1) * IMAGES_PER_PAGE"
		></PrimePaginator>

		<PrimeDataTable
			v-if="images.length > 0"
			:value="images"
			style="width: 100%; padding: 16px"
			resizableColumns
			showGridlines
			stripedRows
			scrollable
		>
			<PrimeColumn field="image" header="Image">
				<template #body="slotProps">
					<PrimeImage
						:src="`https://pculwozsgokcvecdekjo.supabase.co/functions/v1/cors-proxy?url=${slotProps.data.url}`"
						:alt="slotProps.data.url"
						width="50"
						loading="lazy"
						preview
						@load="calculateImageDataAmount($event, slotProps.data)"
					/>
				</template>
			</PrimeColumn>
			<PrimeColumn field="customer_email" header="Customer Email"></PrimeColumn>
			<PrimeColumn field="created_at" header="Added at"></PrimeColumn>
			<PrimeColumn field="is_alive" header="Alive?" bodyStyle="text-align:center">
				<template #body="slotProps">
					<i
						v-if="slotProps.data.is_alive"
						class="pi pi-check"
						style="font-size: 1rem"
					></i>
					<i v-else class="pi pi-times" style="font-size: 1rem"></i>
				</template>
			</PrimeColumn>
			<PrimeColumn header="Data Amount (Valid / Total)">
				<template #body="slotProps">
					<PrimeProgressBar
						v-if="slotProps.data.dataValid > 0"
						:value="
							(slotProps.data.dataValid / MAX_COLOR_DATA_PROPS_COUNT > 1
								? 1
								: slotProps.data.dataValid / MAX_COLOR_DATA_PROPS_COUNT) * 100
						"
					>
						{{ slotProps.data.dataValid }} / {{ slotProps.data.dataTotal }}
					</PrimeProgressBar>
					<p v-else-if="slotProps.data.dataValid === undefined">
						<PrimeSkeleton width="100%"></PrimeSkeleton>
					</p>
					<p v-else>-</p>
				</template>
			</PrimeColumn>
			<PrimeColumn field="last_alive_check" header="Last Alive Check"></PrimeColumn>
			<PrimeColumn field="is_approved" header="Approved?" bodyStyle="text-align:center">
				<template #body="slotProps">
					<PrimeToggleButton
						v-model="slotProps.data.is_approved"
						onIcon="pi pi-check"
						offIcon="pi pi-times"
						:invalid="!slotProps.data.is_approved"
						@change="toggleImage(slotProps.data)"
					/>
				</template>
			</PrimeColumn>
		</PrimeDataTable>

		<div v-else style="margin-top: 30vh">
			<i class="pi pi-spin pi-spinner" style="font-size: 4rem"></i>
		</div>

		<PrimePaginator
			v-if="images.length > 0 && totalImagesCount > IMAGES_PER_PAGE"
			:rows="IMAGES_PER_PAGE"
			:totalRecords="totalImagesCount"
			@page="onPageChanged"
			:first="(currentPage - 1) * IMAGES_PER_PAGE"
		></PrimePaginator>

		<PrimeToast style="margin-top: 64px" />
	</section>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted, watch } from "vue";
import { computedAsync, useTimeAgo } from "@vueuse/core";

import PrimeToolbar from "primevue/toolbar";
import PrimeButton from "primevue/button";
import PrimeToast from "primevue/toast";
import PrimeDataTable from "primevue/datatable";
import PrimeColumn from "primevue/column";
import PrimeImage from "primevue/image";
import PrimeToggleButton from "primevue/togglebutton";
import PrimePaginator from "primevue/paginator";
import PrimeProgressBar from "primevue/progressbar";
import PrimeSkeleton from 'primevue/skeleton';

import { useToast } from "primevue/usetoast";

import { supabase } from "@/ts/supabase";
import { useRoute } from "vue-router";
import router from "@/router";

import exifr from "exifr-webp";

import { formattedXmpDataKeys } from "@/ts/schemas/xmpLightroomFormatted";

const IMAGES_PER_PAGE = 25;
const MAX_COLOR_DATA_PROPS_COUNT = 40;

export default defineComponent({
	name: "HomeView",
	components: {
		PrimeToolbar,
		PrimeButton,
		PrimeToast,
		PrimeDataTable,
		PrimeColumn,
		PrimeImage,
		PrimeToggleButton,
		PrimePaginator,
		PrimeProgressBar,
		PrimeSkeleton
	},

	setup() {
		const toast = useToast();
		const userEmail = computedAsync(async () => {
			const { data, error } = await supabase.auth.getUser();

			return error ? "..." : data.user?.email;
		}, undefined);

		const images = ref<
			{
				url: string;
				customer_email: string | null;
				created_at: string;
				id: string;
				is_alive: boolean;
				last_alive_check: string | null;
				is_approved: boolean;

				// for data amount
				dataTotal?: number;
				dataValid?: number;
			}[]
		>([]);

		const route = useRoute();
		const totalImagesCount = ref(0);

		//

		const currentPage = computed(() => Math.floor(parseInt((route.query.page as any) || 1)));

		async function logout() {
			const { error } = await supabase.auth.signOut({ scope: "local" });

			if (!error) {
				await router.push("login");
			}
		}

		async function fetchImages() {
			const from = (currentPage.value - 1) * IMAGES_PER_PAGE;
			const to = from + IMAGES_PER_PAGE - 1;

			totalImagesCount.value = 0;
			images.value = [];

			const { data, count, error } = await supabase
				.from("images")
				.select("*, images_gallery(image_id, added_at)", { count: "exact" })
				.range(from, to)
				.order("created_at", { ascending: false });

			if (error) {
				toast.add({
					summary: "Unable to fetch images from Supabase",
					detail: error.message,
					severity: "error"
				});

				return;
			}

			if (data.length == 0 && currentPage.value !== 1) {
				toast.add({
					summary: "No data for this page",
					detail: "Heading you to home page in 3 seconds...",
					severity: "warn",
					life: 3000
				});

				setTimeout(() => {
					router.push("/");
				}, 3000);

				return;
			}

			totalImagesCount.value = count || 0;

			images.value = data.map((entry) => {
				return {
					url: entry.url,
					customer_id: entry.customer_id,
					customer_email: entry.customer_email,
					created_at: useTimeAgo(new Date(entry.created_at)).value,
					id: entry.id,
					is_alive: entry.is_alive,
					last_alive_check:
						entry.last_alive_check == undefined
							? "-"
							: useTimeAgo(new Date(entry.last_alive_check)).value,
					is_approved: entry.images_gallery.length > 0,

					dataValid: undefined,
					dataTotal: undefined
				};
			});
		}

		async function toggleImage(image: {
			url: string;
			customer_id: string | null;
			created_at: string;
			id: string;
			is_alive: boolean;
			last_alive_check: string | null;
			is_approved: boolean;
		}) {
			if (image.is_approved) {
				const { error } = await supabase.from("images_gallery").insert({
					image_id: image.id
				});
			} else {
				const { error } = await supabase
					.from("images_gallery")
					.delete()
					.eq("image_id", image.id);

				if (error) {
					console.warn(error);
					image.is_approved = true;
				}
			}
		}

		async function calculateImageDataAmount(
			event: Event,
			slotPropsData: { dataTotal: number; dataValid: number }
		) {
			try {
				const response = await fetch((event.target as HTMLImageElement).src);
				const imageData = await response.arrayBuffer();

				const xmpData = await exifr.parse(imageData, {
					xmp: {
						parse: true
					}
				});

				let propsCounter = 0;
				const xmpKeys = Object.keys(xmpData);

				Object.keys(formattedXmpDataKeys).forEach((propName: string) => {
					if (xmpKeys.includes(propName)) {
						const value = (xmpData as Record<string, any>)[propName];

						const valueType = (formattedXmpDataKeys as Record<string, any>)[propName];

						if (typeof value == "number" && value == 0) {
							return;
						}

						if (typeof value == valueType) {
							propsCounter++;
						}
					}
				});

				slotPropsData.dataValid = propsCounter;
				slotPropsData.dataTotal = xmpKeys.length;
			} catch (ex) {}
		}

		async function onPageChanged(e: { page: number }) {
			await router.push({
				name: "home",
				query: {
					page: e.page + 1
				}
			});
		}

		//

		watch(
			() => route.query.page,
			(newId, oldId) => {
				fetchImages();
			}
		);

		onMounted(async () => {
			fetchImages();
		});

		return {
			IMAGES_PER_PAGE,
			MAX_COLOR_DATA_PROPS_COUNT,

			userEmail,
			images,
			totalImagesCount,
			currentPage,

			logout,
			toggleImage,
			calculateImageDataAmount,
			onPageChanged
		};
	}
});
</script>

<style lang="scss" scoped>
.home-view {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;

	.toolbar-top {
		width: calc(100% - 32px);
		margin: 16px;

		.toolbar-logo {
			@media only screen and (max-width: 600px) {
				display: none;
			}

			@media (prefers-color-scheme: dark) {
				filter: invert(1);
			}
		}
	}
}
</style>
