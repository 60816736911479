import "normalize.css";
import "@/scss/main.scss";

import { createApp } from "vue";
import { createPinia } from "pinia";
import * as Sentry from "@sentry/vue";

import PrimeVue from "primevue/config";
import Aura from "@primevue/themes/aura";
import ToastService from "primevue/toastservice";
import AnimateOnScroll from "primevue/animateonscroll";

import App from "./App.vue";
import router from "./router";

const app = createApp(App);

Sentry.init({
	app,
	dsn: "https://be6d0bbcee7251aef84eb1abd5028795@o4507428468228096.ingest.us.sentry.io/4507812821401600",
	integrations: [
		Sentry.browserTracingIntegration({ router }),
		Sentry.replayIntegration(),
		Sentry.browserProfilingIntegration()
	],
	tracesSampleRate: 1.0,
	tracePropagationTargets: [
		"localhost",
		/^https:\/\/pculwozsgokcvecdekjo\.supabase\.co/,
		/^https:\/\/goodhue\.io/
	],

	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1.0,
	profilesSampleRate: 1.0
});

app.use(createPinia());
app.use(router);
app.use(PrimeVue, {
	theme: {
		preset: Aura
	}
});
app.use(ToastService);

app.directive("animateonscroll", AnimateOnScroll);

app.mount("main");
