/**
 * Array used to remove all the used keys from above from "Other XMP data" table
 */
export const formattedXmpDataKeys = {
	CameraProfile: "string",

	ColorTemperature: "number",
	Tint: "number",
	Exposure: "number",
	Exposure2012: "number",
	Contrast: "number",
	Contrast2012: "number",
	Highlights: "number",
	Highlights2012: "number",
	Shadows: "number",
	Shadows2012: "number",
	Whites: "number",
	Whites2012: "number",
	Blacks: "number",
	Blacks2012: "number",
	Texture: "number",
	Clarity: "number",
	Clarity2012: "number",
	Dehaze: "number",
	Vibrance: "number",
	Saturation: "number",

	HueAdjustmentRed: "number",
	HueAdjustmentOrange: "number",
	HueAdjustmentYellow: "number",
	HueAdjustmentGreen: "number",
	HueAdjustmentAqua: "number",
	HueAdjustmentBlue: "number",
	HueAdjustmentPurple: "number",
	HueAdjustmentMagenta: "number",

	SaturationAdjustmentRed: "number",
	SaturationAdjustmentOrange: "number",
	SaturationAdjustmentYellow: "number",
	SaturationAdjustmentGreen: "number",
	SaturationAdjustmentAqua: "number",
	SaturationAdjustmentBlue: "number",
	SaturationAdjustmentPurple: "number",
	SaturationAdjustmentMagenta: "number",

	LuminanceAdjustmentRed: "number",
	LuminanceAdjustmentOrange: "number",
	LuminanceAdjustmentYellow: "number",
	LuminanceAdjustmentGreen: "number",
	LuminanceAdjustmentAqua: "number",
	LuminanceAdjustmentBlue: "number",
	LuminanceAdjustmentPurple: "number",
	LuminanceAdjustmentMagenta: "number",

	Sharpness: "number",
	SharpenRadius: "number",
	SharpenDetail: "number",
	SharpenEdgeMasking: "number",

	ShadowTint: "number",
	RedHue: "number",
	RedSaturation: "number",
	GreenHue: "number",
	GreenSaturation: "number",
	BlueHue: "number",
	BlueSaturation: "number",

	ColorGradeMidtoneHue: "number",
	ColorGradeMidtoneSat: "number",
	ColorGradeMidtoneLum: "number",

	SplitToningShadowHue: "number",
	ColorGradeShadowHue: "number",
	SplitToningShadowSaturation: "number",
	ColorGradeShadowSat: "number",
	ColorGradeShadowLum: "number",

	SplitToningHighlightHue: "number",
	ColorGradeHighlightHue: "number",
	SplitToningHighlightSaturation: "number",
	ColorGradeHighlightSat: "number",
	ColorGradeHighlightLum: "number",

	ColorGradeBlending: "number",
	SplitToningBalance: "number",

	ToneCurvePV2012: "array",
	ToneCurvePV2012Red: "array",
	ToneCurvePV2012Green: "array",
	ToneCurvePV2012Blue: "array",
};
