<template>
	<section class="login-view">
		<div class="login-form">
			<img class="login-logo" src="@/assets/goodhue-logo.svg" />

			<div class="flex flex-col justify-space-between">
				<label for="username">Username</label>
				<PrimeInputText
					ref="inputEmail"
					id="username"
					v-model="email"
					:disabled="isAuthInProgress"
				/>
			</div>

			<div class="flex flex-col justify-space-between">
				<label for="password">Password</label>
				<PrimePassword
					ref="inputPassword"
					id="password"
					toggleMask
					:feedback="false"
					:disabled="isAuthInProgress"
					v-model="password"
				/>
			</div>

			<div class="flex flex-row">
				<PrimeButton
					ref="buttonLogin"
					label="Login"
					outlined
					style="width: 100%"
					:disabled="isAuthInProgress"
					@click="doLogin"
				/>
			</div>
		</div>

		<PrimeToast />
	</section>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import PrimeInputText from "primevue/inputtext";
import PrimePassword from "primevue/password";
import PrimeButton from "primevue/button";
import PrimeToast from "primevue/toast";

import { useToast } from "primevue/usetoast";

import { supabase } from "@/ts/supabase";
import router from "@/router";

export default defineComponent({
	name: "LoginView",
	components: { PrimeInputText, PrimePassword, PrimeButton, PrimeToast },

	setup() {
		const email = ref("");
		const password = ref("");

		const isAuthInProgress = ref(false);

		const inputEmail = ref<InstanceType<typeof PrimeInputText>>();
		const inputPassword = ref<InstanceType<typeof PrimePassword>>();
		const buttonLogin = ref<InstanceType<typeof PrimeButton>>();

		const toast = useToast();

		async function doLogin() {
			try {
				isAuthInProgress.value = true;

				const { error } = await supabase.auth.signInWithPassword({
					email: email.value,
					password: password.value
				});

				if (error) {
					toast.add({ summary: error.name, detail: error.message, severity: "error" });
					console.error(error);
					return;
				}

				await router.push("/");
			} finally {
				isAuthInProgress.value = false;
			}
		}

		return {
			email,
			password,

			isAuthInProgress,

			inputEmail,
			inputPassword,
			buttonLogin,

			doLogin
		};
	}
});
</script>

<style lang="scss" scoped>
section.login-view {
	width: 100%;
	height: 100%;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	color: var(--p-surface-0);

	.login-form {
		padding: 32px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		gap: 16px;
		align-items: center;

		.login-logo {
			margin-bottom: 32px;

			@media (prefers-color-scheme: dark) {
				filter: invert(1);
			}
		}

		.flex-col,
		.flex-row {
			width: 20rem;

			label {
				color: var(--p-surface-400);
			}

			gap: 8px;
		}
	}
}
</style>
